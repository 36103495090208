function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

/*! modernizr 3.6.0 (Custom Build) | MIT *
 * https://modernizr.com/download/?-cssgrid_cssgridlegacy-csspositionsticky-focuswithin-placeholder-touchevents-setclasses !*/
!function (e, n, t) {
  function r(e, n) {
    return _typeof(e) === n;
  }

  function o() {
    var e, n, t, o, s, i, a;

    for (var l in w) {
      if (w.hasOwnProperty(l)) {
        if (e = [], n = w[l], n.name && (e.push(n.name.toLowerCase()), n.options && n.options.aliases && n.options.aliases.length)) for (t = 0; t < n.options.aliases.length; t++) {
          e.push(n.options.aliases[t].toLowerCase());
        }

        for (o = r(n.fn, "function") ? n.fn() : n.fn, s = 0; s < e.length; s++) {
          i = e[s], a = i.split("."), 1 === a.length ? Modernizr[a[0]] = o : (!Modernizr[a[0]] || Modernizr[a[0]] instanceof Boolean || (Modernizr[a[0]] = new Boolean(Modernizr[a[0]])), Modernizr[a[0]][a[1]] = o), C.push((o ? "" : "no-") + a.join("-"));
        }
      }
    }
  }

  function s(e) {
    var n = x.className,
        t = Modernizr._config.classPrefix || "";

    if (T && (n = n.baseVal), Modernizr._config.enableJSClass) {
      var r = new RegExp("(^|\\s)" + t + "no-js(\\s|$)");
      n = n.replace(r, "$1" + t + "js$2");
    }

    Modernizr._config.enableClasses && (n += " " + t + e.join(" " + t), T ? x.className.baseVal = n : x.className = n);
  }

  function i() {
    return "function" != typeof n.createElement ? n.createElement(arguments[0]) : T ? n.createElementNS.call(n, "http://www.w3.org/2000/svg", arguments[0]) : n.createElement.apply(n, arguments);
  }

  function a() {
    var e = n.body;
    return e || (e = i(T ? "svg" : "body"), e.fake = !0), e;
  }

  function l(e, t, r, o) {
    var s,
        l,
        u,
        f,
        c = "modernizr",
        d = i("div"),
        p = a();
    if (parseInt(r, 10)) for (; r--;) {
      u = i("div"), u.id = o ? o[r] : c + (r + 1), d.appendChild(u);
    }
    return s = i("style"), s.type = "text/css", s.id = "s" + c, (p.fake ? p : d).appendChild(s), p.appendChild(d), s.styleSheet ? s.styleSheet.cssText = e : s.appendChild(n.createTextNode(e)), d.id = c, p.fake && (p.style.background = "", p.style.overflow = "hidden", f = x.style.overflow, x.style.overflow = "hidden", x.appendChild(p)), l = t(d, e), p.fake ? (p.parentNode.removeChild(p), x.style.overflow = f, x.offsetHeight) : d.parentNode.removeChild(d), !!l;
  }

  function u(e, n) {
    return function () {
      return e.apply(n, arguments);
    };
  }

  function f(e, n, t) {
    var o;

    for (var s in e) {
      if (e[s] in n) return t === !1 ? e[s] : (o = n[e[s]], r(o, "function") ? u(o, t || n) : o);
    }

    return !1;
  }

  function c(e, n) {
    return !!~("" + e).indexOf(n);
  }

  function d(e) {
    return e.replace(/([a-z])-([a-z])/g, function (e, n, t) {
      return n + t.toUpperCase();
    }).replace(/^-/, "");
  }

  function p(n, t, r) {
    var o;

    if ("getComputedStyle" in e) {
      o = getComputedStyle.call(e, n, t);
      var s = e.console;
      if (null !== o) r && (o = o.getPropertyValue(r));else if (s) {
        var i = s.error ? "error" : "log";
        s[i].call(s, "getComputedStyle returning null, its possible modernizr test results are inaccurate");
      }
    } else o = !t && n.currentStyle && n.currentStyle[r];

    return o;
  }

  function m(e) {
    return e.replace(/([A-Z])/g, function (e, n) {
      return "-" + n.toLowerCase();
    }).replace(/^ms-/, "-ms-");
  }

  function h(n, r) {
    var o = n.length;

    if ("CSS" in e && "supports" in e.CSS) {
      for (; o--;) {
        if (e.CSS.supports(m(n[o]), r)) return !0;
      }

      return !1;
    }

    if ("CSSSupportsRule" in e) {
      for (var s = []; o--;) {
        s.push("(" + m(n[o]) + ":" + r + ")");
      }

      return s = s.join(" or "), l("@supports (" + s + ") { #modernizr { position: absolute; } }", function (e) {
        return "absolute" == p(e, null, "position");
      });
    }

    return t;
  }

  function y(e, n, o, s) {
    function a() {
      u && (delete k.style, delete k.modElem);
    }

    if (s = r(s, "undefined") ? !1 : s, !r(o, "undefined")) {
      var l = h(e, o);
      if (!r(l, "undefined")) return l;
    }

    for (var u, f, p, m, y, v = ["modernizr", "tspan", "samp"]; !k.style && v.length;) {
      u = !0, k.modElem = i(v.shift()), k.style = k.modElem.style;
    }

    for (p = e.length, f = 0; p > f; f++) {
      if (m = e[f], y = k.style[m], c(m, "-") && (m = d(m)), k.style[m] !== t) {
        if (s || r(o, "undefined")) return a(), "pfx" == n ? m : !0;

        try {
          k.style[m] = o;
        } catch (g) {}

        if (k.style[m] != y) return a(), "pfx" == n ? m : !0;
      }
    }

    return a(), !1;
  }

  function v(e, n, t, o, s) {
    var i = e.charAt(0).toUpperCase() + e.slice(1),
        a = (e + " " + P.join(i + " ") + i).split(" ");
    return r(n, "string") || r(n, "undefined") ? y(a, n, o, s) : (a = (e + " " + j.join(i + " ") + i).split(" "), f(a, n, t));
  }

  function g(e, n, r) {
    return v(e, t, t, n, r);
  }

  var C = [],
      w = [],
      S = {
    _version: "3.6.0",
    _config: {
      classPrefix: "",
      enableClasses: !0,
      enableJSClass: !0,
      usePrefixes: !0
    },
    _q: [],
    on: function on(e, n) {
      var t = this;
      setTimeout(function () {
        n(t[e]);
      }, 0);
    },
    addTest: function addTest(e, n, t) {
      w.push({
        name: e,
        fn: n,
        options: t
      });
    },
    addAsyncTest: function addAsyncTest(e) {
      w.push({
        name: null,
        fn: e
      });
    }
  },
      Modernizr = function Modernizr() {};

  Modernizr.prototype = S, Modernizr = new Modernizr(), Modernizr.addTest("focuswithin", function () {
    try {
      n.querySelector(":focus-within");
    } catch (e) {
      return !1;
    }

    return !0;
  });

  var x = n.documentElement,
      _ = S._config.usePrefixes ? " -webkit- -moz- -o- -ms- ".split(" ") : ["", ""];

  S._prefixes = _;
  var T = "svg" === x.nodeName.toLowerCase();
  Modernizr.addTest("csspositionsticky", function () {
    var e = "position:",
        n = "sticky",
        t = i("a"),
        r = t.style;
    return r.cssText = e + _.join(n + ";" + e).slice(0, -e.length), -1 !== r.position.indexOf(n);
  }), Modernizr.addTest("placeholder", "placeholder" in i("input") && "placeholder" in i("textarea"));
  var b = S.testStyles = l;
  Modernizr.addTest("touchevents", function () {
    var t;
    if ("ontouchstart" in e || e.DocumentTouch && n instanceof DocumentTouch) t = !0;else {
      var r = ["@media (", _.join("touch-enabled),("), "heartz", ")", "{#modernizr{top:9px;position:absolute}}"].join("");
      b(r, function (e) {
        t = 9 === e.offsetTop;
      });
    }
    return t;
  });
  var z = "Moz O ms Webkit",
      P = S._config.usePrefixes ? z.split(" ") : [];
  S._cssomPrefixes = P;
  var j = S._config.usePrefixes ? z.toLowerCase().split(" ") : [];
  S._domPrefixes = j;
  var E = {
    elem: i("modernizr")
  };

  Modernizr._q.push(function () {
    delete E.elem;
  });

  var k = {
    style: E.elem.style
  };
  Modernizr._q.unshift(function () {
    delete k.style;
  }), S.testAllProps = v, S.testAllProps = g, Modernizr.addTest("cssgridlegacy", g("grid-columns", "10px", !0)), Modernizr.addTest("cssgrid", g("grid-template-rows", "none", !0)), o(), s(C), delete S.addTest, delete S.addAsyncTest;

  for (var N = 0; N < Modernizr._q.length; N++) {
    Modernizr._q[N]();
  }

  e.Modernizr = Modernizr;
}(window, document);